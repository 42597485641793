import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  upload(path: string, file: any): any {
    return firebase.storage().ref(path).put(file);
  }

  uploadString(path: string, file: any): any {
    return firebase.storage().ref(path).putString(file, 'data_url');
  }
}
