import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserValue;
  public currentUserChange: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
  ) {
    firebase.auth().onAuthStateChanged((user) => {
      this.currentUser = user;
    });

    this.currentUserChange.subscribe((value) => {
        this.currentUserValue = value;
    });
  }

  set currentUser(newValue: any) {
    this.currentUserChange.next(newValue);
  }

  get currentUser(): any {
    return this.currentUserValue;
  }

  requireAuth(): Promise<any> {
    return new Promise((resolve) => {
      this.currentUserChange.subscribe((value) => {
        if (!value) {
          this.router.navigate(['login']);
        } else {
          resolve(value);
        }
      });
    });
  }

  signInEmail(email: string, password: string): Promise<any> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  signOut(): Promise<any> {
    return firebase.auth().signOut();
  }

  udpateProfile(profile: any): Promise<any>  {
    return firebase.auth().currentUser.updateProfile(profile);
  }

  updatePassword(newPassword: any): Promise<any>  {
    return firebase.auth().currentUser.updatePassword(newPassword);
  }
}
