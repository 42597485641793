import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.styl']
})
export class TagInputComponent implements OnInit {

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input()
  placeholder = 'placeholder';

  @Input()
  visible = true;

  @Input()
  selectable = true;

  @Input()
  removable = true;

  @Input()
  addOnBlur = true;

  modelValue: any[] = [];

  @Output() modelChange = new EventEmitter();

  @Input()
  get model(): any {
    return this.modelValue;
  }

  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);
  }

  constructor() { }

  ngOnInit(): void {
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      const model = [...this.model];
      model.push({name: value.trim()});
      this.model = model;
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(item: any): void {
    const index = this.model.indexOf(item);

    if (index >= 0) {
      const model = [...this.model];
      model.splice(index, 1);
      this.model = model;
    }
  }

}
