import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

import { Platform } from '@angular/cdk/platform';

import { StyleRenderer, lyl, WithStyles } from '@alyle/ui';
import { ImgCropperConfig, ImgCropperErrorEvent, ImgCropperEvent, LyImageCropper } from '@alyle/ui/image-cropper';


const STYLES = () => ({
  cropper: lyl `{
    max-width: 400px
    height: 200px
  }`,
  sliderContainer: lyl `{
    text-align: center
    max-width: 400px
    margin: 14px
  }`
});

@Component({
  selector: 'app-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.styl'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ]
})
export class ImageInputComponent implements OnInit, AfterViewInit, WithStyles {

  imageName: any;
  image: any;
  imagePreview: any;
  imagePreviewCropped: any;

  classes = this.sRenderer.renderSheet(STYLES);
  croppedImage?: string;
  scale: number;
  ready: boolean;
  minScale: number;
  @ViewChild(LyImageCropper) cropper: LyImageCropper;
  myConfig: ImgCropperConfig = {
    // autoCrop: true,
    width: 200, // Default `250`
    height: 200, // Default `200`
    fill: '#ff2997', // Default transparent if type = png else #000
    type: 'image/png', // Or you can also use `image/jpeg`
  };


  autoTicks = false;
  disabled = false;
  invert = false;
  max = 1;
  showTicks = false;
  step = 0.000001;
  thumbLabel = false;
  value = 0;
  vertical = true;
  tickInterval = 1;

  modelValue: any[] = [];

  @Output() modelChange = new EventEmitter();

  @Input()
  get model(): any {
    return this.modelValue;
  }

  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);
  }

  constructor(
    readonly sRenderer: StyleRenderer,
    private _platform: Platform
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    // demo: Load image from URL and update position, scale, rotate
    // this is supported only for browsers
    if (this._platform.isBrowser) {
      const config = {
        scale: 0.745864772531767,
        position: {
          xOrigin: 642.380608078103,
          yOrigin: 236.26357452128866
        }
      };
      // this.cropper.setImageUrl(
      //   'https://firebasestorage.googleapis.com/v0/b/alyle-ui.appspot.com/o/img%2Flarm-rmah-47685-unsplash-1.png?alt=media&token=96a29be5-e3ef-4f71-8437-76ac8013372c',
      //   () => {
      //     this.cropper.setScale(config.scale, true);
      //     this.cropper.updatePosition(config.position.xOrigin, config.position.yOrigin);
      //     // You can also rotate the image
      //     // this.cropper.rotate(90);
      //   }
      // );
    }

  }


  onCropped(e: ImgCropperEvent): void {
    this.croppedImage = e.dataURL;
    this.model = e.dataURL;
    console.log('cropped img: ', e);
  }
  onLoaded(e: ImgCropperEvent): void {
    console.log('img loaded', e);
  }
  onError(e: ImgCropperErrorEvent): void {
    console.warn(`'${e.name}' is not a valid image`, e);
  }


  fileChange(event: any): void {
    this.cropper.selectInputEvent(event)
    this.image = event.target.files[0]
    this.imageName = this.image.name;

    console.log(this.image);
  }


  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }

    return 0;
  }

}
