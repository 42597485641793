import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '@service/common/auth';
import { Loader } from '@util/loader.class';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.styl']
})
export class LoginComponent implements OnInit {

  loaded = false;
  loader = new Loader({
    auth: false
  });

  email;
  password;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) {


    console.log(this.route.snapshot.data.title);

  }

  ngOnInit(): void {
    this.loader.loaded.subscribe((value) => {
      this.loaded = value;
    });

    this.auth.currentUserChange.subscribe((user) => {
      if (user) {
        this.router.navigate(['home']);
      } else {
        this.loader.load('auth');
      }
    });

    if (!this.auth.currentUser) {
      setTimeout(() => {
        this.loader.load('auth');
      }, 200);
    }
  }


  login(): void {
    console.log(this.email, this.password);
    this.auth.signInEmail(this.email, this.password).catch((error) => {
      console.log(error);
    });
  }


}
