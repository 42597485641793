import { NgModule } from '@angular/core';

import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatRippleModule} from '@angular/material/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatSliderModule} from '@angular/material/slider';


import { LyImageCropperModule } from '@alyle/ui/image-cropper';


@NgModule({
  imports: [
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatRippleModule,
    MatSidenavModule,
    MatChipsModule,
    MatIconModule,
    DragDropModule,
    MatSliderModule,
    LyImageCropperModule,
  ],
  exports: [
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatRippleModule,
    MatSidenavModule,
    MatChipsModule,
    MatIconModule,
    DragDropModule,
    MatSliderModule,
    LyImageCropperModule,
  ]
})
export class MaterialModule { }
