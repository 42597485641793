import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormGroup, FormControl, ValidatorFn, Validators} from '@angular/forms';

import { AuthService } from '@service/common/auth';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.styl']
})
export class ProfileComponent implements OnInit {

  form = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(2)]),
    passwordConfirm: new FormControl('', [Validators.required, Validators.minLength(2)]),
  }, this.passwordMatchValidator );

  currentUser: any = {};

  constructor(
    private auth: AuthService,
  ) {
    this.auth.currentUserChange.subscribe((user: any) => {
      if (user) {
        this.currentUser = {...user};
      }
    });

    if (this.auth.currentUser) {
      this.currentUser = {...this.auth.currentUser};
    }
  }

  ngOnInit(): void {
  }

  passwordMatchValidator(g: FormGroup): any {
    return g.get('password').value === g.get('passwordConfirm').value
       ? null : {mismatch: true};
  }


  update(): void {
    console.log(this.currentUser);
    this.auth.udpateProfile({
      displayName: this.currentUser.displayName
    });
  }

  changePassword(): void  {
    console.log(this.form.get('password').value, this.form.get('passwordConfirm').value);
    if (this.form.get('password').value === this.form.get('passwordConfirm').value) {
      this.auth.updatePassword(this.form.get('password').value);
    }
  }

}
