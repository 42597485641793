import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { from } from 'rxjs';

import { numericID } from '@util/generator.functions';
import { getStringCharCodes } from '@util/format.functions';

import { StorageService } from '@service/common/storage';
import { DatabaseService } from '@service/common/database';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.styl'],
})
export class ProductsComponent implements OnInit, OnDestroy {

  unsubscribe;
  productsO: any = {};
  products: any[] = [];




  newProduct = new FormGroup({
    commonName: new FormControl('', [Validators.required]),
    species: new FormControl('', [Validators.required]),
    botanicalName: new FormControl('', [Validators.required]),
    cost: new FormControl('', [Validators.required]),
  } );
  image: any;
  tags: any[] = [];



  constructor(
    private storage: StorageService,
    private database: DatabaseService,
  ) { }


  ngOnInit(): void {
    this.unsubscribe = this.database.listen('products', (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = {id: change.doc.id, ...change.doc.data()};
        doc.tags = doc.tags ? JSON.parse(doc.tags) : undefined;
        console.log(doc);
        this.productsO[change.doc.id] = doc;
      });

      this.products = Object.values(this.productsO);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }


  addProduct(): void {
    const id = getStringCharCodes( numericID(2) );
    console.log(this.image, this.tags);

    if (this.image) {
      const uploadTask = this.storage.uploadString(`product/${id}.png`, this.image);
      uploadTask.on('state_changed', (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        // switch (snapshot.state) {
        //   case firebase.storage.TaskState.PAUSED: // or 'paused'
        //     console.log('Upload is paused');
        //     break;
        //   case firebase.storage.TaskState.RUNNING: // or 'running'
        //     console.log('Upload is running');
        //     break;
        // }
      }, (error) => {
        // Handle unsuccessful uploads
      }, () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log('File available at', downloadURL);
          this.database.set('products', id, {
            image: downloadURL,
            tags: this.tags ? JSON.stringify(this.tags) : undefined,
            commonName: this.newProduct.get('commonName').value,
            species: this.newProduct.get('species').value,
            botanicalName: this.newProduct.get('botanicalName').value,
            cost: parseFloat(this.newProduct.get('cost').value)
          });
        });
      });

    } else {
      this.database.set('products', id, {
        tags: this.tags ? JSON.stringify(this.tags) : undefined,
        commonName: this.newProduct.get('commonName').value,
        species: this.newProduct.get('species').value,
        botanicalName: this.newProduct.get('botanicalName').value,
        cost: parseFloat(this.newProduct.get('cost').value)
      });
    }
  }

}
