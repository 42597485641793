import { Subject } from 'rxjs';

export class Loader {

  public loaded: Subject<boolean> = new Subject<boolean>();

  constructor(
    private chunks: any
  ) {}

  load(chunk: string): void {
    this.chunks[chunk] = true;
    if (Object.values(this.chunks).every(item => item === true)) {
      this.loaded.next(true);
    }
  }

}
