import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor() { }

  get(collection: string, document: string): Promise<any> {
    return firebase.firestore().collection(collection).doc(document).get();
  }

  add(collection: string, data: any): Promise<any> {
    return firebase.firestore().collection(collection).add(data);
  }

  set(collection: string, document: any, data: any): Promise<any> {
    return firebase.firestore().collection(collection).doc(document).set(data);
  }

  listen(collection: string, callback: any): any  {
    return firebase.firestore().collection(collection).onSnapshot(callback);
  }
}
