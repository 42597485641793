import { Component } from '@angular/core';

import { RouterService } from '@service/common/router';
import { AuthService } from '@service/common/auth';
import { ThemeVariables, ThemeRef, lyl, StyleRenderer } from '@alyle/ui';

const STYLES = (theme: ThemeVariables, ref: ThemeRef) => {
  const __ = ref.selectorsOf(STYLES);
  return {
    $global: lyl `{
      body {
        background-color: ${theme.background.default}
        color: ${theme.text.default}
        font-family: ${theme.typography.fontFamily}
        margin: 0
        direction: ${theme.direction}
      }
    }`,
    root: lyl `{
      display: block
    }`
  };
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl'],
  providers: [StyleRenderer]
})
export class AppComponent {
  readonly classes = this.sRenderer.renderSheet(STYLES, true);


  routeType = '';

  constructor(readonly sRenderer: StyleRenderer,
              private router: RouterService,
              private auth: AuthService) {
    router.getRoute().subscribe(route => {
      console.log(route);
      this.routeType = route.data._value.type;
    });
  }
}
