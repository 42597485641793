import { Component, OnInit } from '@angular/core';

import { AuthService } from '@service/common/auth';
import { Loader } from '@util/loader.class';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl']
})
export class HeaderComponent implements OnInit {

  loaded = false;
  loader = new Loader({
    auth: false
  });

  currentUser;

  constructor(
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.loader.loaded.subscribe((value) => {
      this.loaded = value;
    });

    this.auth.requireAuth().then((user) => {
      this.loader.load('auth');
      this.currentUser = user;
    });

    if (this.auth.currentUser) {
      this.currentUser = this.auth.currentUser;
      this.loader.load('auth');
    }
  }


  logout(): void {
    this.auth.signOut();
  }
}
