import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


const firebaseConfig = {
  apiKey: 'AIzaSyCOADaTgxRtu80TnRX9V-qH7vG9fyO3IR8',
  authDomain: 'olimpia-inventory.firebaseapp.com',
  databaseURL: 'https://olimpia-inventory.firebaseio.com',
  projectId: 'olimpia-inventory',
  storageBucket: 'olimpia-inventory.appspot.com',
  messagingSenderId: '270947559183',
  appId: '1:270947559183:web:dd1bb30bb29abb854cc07a'
};
firebase.initializeApp(firebaseConfig);
